h1, h2, h3, h4 {
  font-weight: 600;
  line-height: 160%;
}

h1 {
  font-size: 45px;
}

h2 {
  font-size: 35px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 20px;
}

.@{class-prefix}-text {
  &-white {
    color: @white !important;
  }

  &-muted {
    color: @grey-7 !important;;
  }

  &-dark {
    color: @dark-color !important;
  }

  &-dark-blue {
    color: @dark-blue !important;;
  }

  &-grey {
    color: @grey-5 !important;
  }

  &-red {
    color: @error-color;
  }
}

a {
  &:hover, &:focus {
    &.@{class-prefix}-text {
      &-white {
        color: fade(@white, 80%) !important;;
      }
    }
  }
}

.@{class-prefix}-font-weight {
  &-600 {
    font-weight: 600 !important;
  }
}


// Font Weight class
.@{class-prefix}-font-weight-light {
  font-weight: @font-weight-light !important;
}

.@{class-prefix}-font-weight-normal {
  font-weight: @font-weight-normal !important;
}

.@{class-prefix}-font-weight-medium {
  font-weight: @font-weight-medium !important;
}

.@{class-prefix}-font-weight-semi-bold {
  font-weight: @font-weight-semi-bold !important;
}

.@{class-prefix}-font-weight-bold {
  font-weight: @font-weight-bold !important;
}

.@{class-prefix}-font-weight-heavy {
  font-weight: @font-weight-heavy !important;
}


// Text class
.@{class-prefix}-text-justify {
  text-align: justify !important;
}

.@{class-prefix}-text-nowrap {
  white-space: nowrap !important;
}

.@{class-prefix}-text-wrap {
  white-space: normal !important;
}

.@{class-prefix}-text-left {
  text-align: left !important;
}

.@{class-prefix}-text-right {
  text-align: right !important;
}

@media (min-width: @screen-md-min) {
  .@{class-prefix}-text-md-right {
    text-align: right !important;
  }
}

.@{class-prefix}-text-center {
  text-align: center !important;
}




/*Font size Classes*/
/*8px*/
.@{class-prefix}-fs-xxs {
  font-size: @font-size-sm - 4px;
}

/*10px*/
.@{class-prefix}-fs-xs {
  font-size: @font-size-sm - 2px;
}

/*11px*/
.@{class-prefix}-fs-11 {
  font-size: @font-size-sm - 1px;
}

/*12px*/
.@{class-prefix}-fs-sm {
  font-size: @font-size-sm;
  line-height: 1.5;
}

/*14px*/
.@{class-prefix}-fs-md {
  font-size: @font-size-base;
  line-height: 1;
}

/*16px*/
.@{class-prefix}-fs-lg {
  font-size: @font-size-lg;
  line-height: 1;
}

/*20px*/
.@{class-prefix}-fs-xl {
  font-size: @font-size-lg + 4px;
  line-height: 1;
}

/*24px*/
.@{class-prefix}-fs-xxl {
  font-size: @font-size-lg + 8px;
  line-height: 1.3;

  @media screen and (max-width: @screen-lg-max) {
    font-size: @font-size-lg + 4px;
  }
}

/*28px*/
.@{class-prefix}-fs-2xl {
  font-size: (2*@font-size-base) !important;
  line-height: 1;

  @media screen and (max-width: 1199px) {
    font-size: (2*@font-size-base - 8) !important;
  }
}

/*30px*/
.@{class-prefix}-fs-xxxl {
  font-size: 2*@font-size-lg - 2px;
  line-height: 1;

  @media screen and (max-width: (@screen-xl-min + 166px)) {
    font-size: 2*@font-size-lg - 4px;
  }

  @media screen and (max-width: @screen-lg-max) {
    font-size: 2*@font-size-lg - 6px;
  }

  @media screen and (max-width: @screen-md-max) {
    font-size: 2*@font-size-lg - 8px;
  }
}

/*40px*/
.@{class-prefix}-fs-xlxl {
  font-size: 2*@font-size-lg + 8px;
  line-height: 1;

  @media screen and (max-width: @screen-lg-max) {
    font-size: 2*@font-size-lg;
  }

  @media screen and (max-width: @screen-md-max) {
    font-size: 2*@font-size-lg - 4px;
  }
}


.@{class-prefix}-opacity-80 {
  opacity: .8;
}

.@{class-prefix}-text-strikethrough {
  text-decoration: line-through !important;
}
