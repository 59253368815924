/*Margin Padding Styles*/
.@{class-prefix}-mb-30 {
  margin-bottom: 30px !important;
}

.@{class-prefix}-mb-10 {
  margin-bottom: 10px !important;
}

.@{class-prefix}-m-0 {
  margin: 0 !important;
}

.@{class-prefix}-mt-0,
.@{class-prefix}-my-0 {
  margin-top: 0 !important;
}

.@{class-prefix}-mr-0,
.@{class-prefix}-mx-0 {
  margin-right: 0 !important;
}

.@{class-prefix}-mb-0,
.@{class-prefix}-my-0 {
  margin-bottom: 0 !important;
}

.@{class-prefix}-ml-0,
.@{class-prefix}-mx-0 {
  margin-left: 0 !important;
}

.@{class-prefix}-m-1 {
  margin: 0.25rem !important;
}

.@{class-prefix}-mt-1,
.@{class-prefix}-my-1 {
  margin-top: 0.25rem !important;
}

.@{class-prefix}-mr-1,
.@{class-prefix}-mx-1 {
  margin-right: 0.25rem !important;
}

.@{class-prefix}-mb-1,
.@{class-prefix}-my-1 {
  margin-bottom: 0.25rem !important;
}

.@{class-prefix}-ml-1,
.@{class-prefix}-mx-1 {
  margin-left: 0.25rem !important;
}

.@{class-prefix}-m-2 {
  margin: 0.5rem !important;
}

.@{class-prefix}-mt-2,
.@{class-prefix}-my-2 {
  margin-top: 0.5rem !important;
}

.@{class-prefix}-mr-2,
.@{class-prefix}-mx-2 {
  margin-right: 0.5rem !important;
}

.@{class-prefix}-mb-2,
.@{class-prefix}-my-2 {
  margin-bottom: 0.5rem !important;
}

.@{class-prefix}-ml-2,
.@{class-prefix}-mx-2 {
  margin-left: 0.5rem !important;
}

.@{class-prefix}-m-3 {
  margin: 1rem !important;
}

.@{class-prefix}-mt-3,
.@{class-prefix}-my-3 {
  margin-top: 1rem !important;
}

.@{class-prefix}-mr-3,
.@{class-prefix}-mx-3 {
  margin-right: 1rem !important;
}

.@{class-prefix}-mb-3,
.@{class-prefix}-my-3 {
  margin-bottom: 1rem !important;
}

.@{class-prefix}-ml-3,
.@{class-prefix}-mx-3 {
  margin-left: 1rem !important;
}

.@{class-prefix}-m-4 {
  margin: 1.5rem !important;
}

.@{class-prefix}-mt-4,
.@{class-prefix}-my-4 {
  margin-top: 1.5rem !important;
}

.@{class-prefix}-mr-4,
.@{class-prefix}-mx-4 {
  margin-right: 1.5rem !important;
}

.@{class-prefix}-mb-4,
.@{class-prefix}-my-4 {
  margin-bottom: 1.5rem !important;
}

.@{class-prefix}-ml-4,
.@{class-prefix}-mx-4 {
  margin-left: 1.5rem !important;
}

.@{class-prefix}-m-5 {
  margin: 3rem !important;
}

.@{class-prefix}-mt-5,
.@{class-prefix}-my-5 {
  margin-top: 3rem !important;
}

.@{class-prefix}-mr-5,
.@{class-prefix}-mx-5 {
  margin-right: 3rem !important;
}

.@{class-prefix}-mb-5,
.@{class-prefix}-my-5 {
  margin-bottom: 3rem !important;
}

.@{class-prefix}-ml-5,
.@{class-prefix}-mx-5 {
  margin-left: 3rem !important;
}

.@{class-prefix}-m-6 {
  margin: 3.5rem !important;
}

.@{class-prefix}-mt-6,
.@{class-prefix}-my-6 {
  margin-top: 3.5rem !important;
}

.@{class-prefix}-mt-100 {
  margin-top: 100px !important;
}

.@{class-prefix}-mr-6,
.@{class-prefix}-mx-6 {
  margin-right: 3.5rem !important;
}

.@{class-prefix}-mb-6,
.@{class-prefix}-my-6 {
  margin-bottom: 3.5rem !important;
}

.@{class-prefix}-ml-6,
.@{class-prefix}-mx-6 {
  margin-left: 3.5rem !important;
}

.@{class-prefix}-p-0 {
  padding: 0 !important;
}

.@{class-prefix}-pt-0,
.@{class-prefix}-py-0 {
  padding-top: 0 !important;
}

.@{class-prefix}-pr-0,
.@{class-prefix}-px-0 {
  padding-right: 0 !important;
}

.@{class-prefix}-pb-0,
.@{class-prefix}-py-0 {
  padding-bottom: 0 !important;
}

.@{class-prefix}-pl-0,
.@{class-prefix}-px-0 {
  padding-left: 0 !important;
}

.@{class-prefix}-p-1 {
  padding: 0.25rem !important;
}

.@{class-prefix}-pt-1,
.@{class-prefix}-py-1 {
  padding-top: 0.25rem !important;
}

.@{class-prefix}-pr-1,
.@{class-prefix}-px-1 {
  padding-right: 0.25rem !important;
}

.@{class-prefix}-pb-1,
.@{class-prefix}-py-1 {
  padding-bottom: 0.25rem !important;
}

.@{class-prefix}-pl-1,
.@{class-prefix}-px-1 {
  padding-left: 0.25rem !important;
}

.@{class-prefix}-p-2 {
  padding: 0.5rem !important;
}

.@{class-prefix}-pt-2,
.@{class-prefix}-py-2 {
  padding-top: 0.5rem !important;
}

.@{class-prefix}-pr-2,
.@{class-prefix}-px-2 {
  padding-right: 0.5rem !important;
}

.@{class-prefix}-pb-2,
.@{class-prefix}-py-2 {
  padding-bottom: 0.5rem !important;
}

.@{class-prefix}-pl-2,
.@{class-prefix}-px-2 {
  padding-left: 0.5rem !important;
}

.@{class-prefix}-p-3 {
  padding: 1rem !important;
}

.@{class-prefix}-pt-3,
.@{class-prefix}-py-3 {
  padding-top: 1rem !important;
}

.@{class-prefix}-pr-3,
.@{class-prefix}-px-3 {
  padding-right: 1rem !important;
}

.@{class-prefix}-pb-3,
.@{class-prefix}-py-3 {
  padding-bottom: 1rem !important;
}

.@{class-prefix}-pl-3,
.@{class-prefix}-px-3 {
  padding-left: 1rem !important;
}

.@{class-prefix}-p-4 {
  padding: 1.5rem !important;
}

.@{class-prefix}-pt-4,
.@{class-prefix}-py-4 {
  padding-top: 1.5rem !important;
}

.@{class-prefix}-pr-4,
.@{class-prefix}-px-4 {
  padding-right: 1.5rem !important;
}

.@{class-prefix}-pb-4,
.@{class-prefix}-py-4 {
  padding-bottom: 1.5rem !important;
}

.@{class-prefix}-pl-4,
.@{class-prefix}-px-4 {
  padding-left: 1.5rem !important;
}

.@{class-prefix}-p-5 {
  padding: 2rem !important;
}

.@{class-prefix}-pt-5,
.@{class-prefix}-py-5 {
  padding-top: 2rem !important;
}

.@{class-prefix}-pr-5,
.@{class-prefix}-px-5 {
  padding-right: 2rem !important;
}

.@{class-prefix}-pb-5,
.@{class-prefix}-py-5 {
  padding-bottom: 2rem !important;
}

.@{class-prefix}-pl-5,
.@{class-prefix}-px-5 {
  padding-left: 2rem !important;
}

.@{class-prefix}-p-6 {
  padding: 2.5rem !important;
}

.@{class-prefix}-pt-6,
.@{class-prefix}-py-6 {
  padding-top: 2.5rem !important;
}

.@{class-prefix}-pr-6,
.@{class-prefix}-px-6 {
  padding-right: 2.5rem !important;
}

.@{class-prefix}-pb-6,
.@{class-prefix}-py-6 {
  padding-bottom: 2.5rem !important;
}

.@{class-prefix}-pl-6,
.@{class-prefix}-px-6 {
  padding-left: 2.5rem !important;
}

.@{class-prefix}-p-7 {
  padding: 3rem !important;
}

.@{class-prefix}-pt-7,
.@{class-prefix}-py-7 {
  padding-top: 3rem !important;
}

.@{class-prefix}-pr-7,
.@{class-prefix}-px-7 {
  padding-right: 3rem !important;
}

.@{class-prefix}-pb-7,
.@{class-prefix}-py-7 {
  padding-bottom: 3rem !important;
}

.@{class-prefix}-pl-7,
.@{class-prefix}-px-7 {
  padding-left: 3rem !important;
}

.@{class-prefix}-p-8 {
  padding: 3.5rem !important;
}

.@{class-prefix}-pt-8,
.@{class-prefix}-py-8 {
  padding-top: 3.5rem !important;
}

.@{class-prefix}-pr-8,
.@{class-prefix}-px-8 {
  padding-right: 3.5rem !important;
}

.@{class-prefix}-pb-8,
.@{class-prefix}-py-8 {
  padding-bottom: 3.5rem !important;
}

.@{class-prefix}-pl-8,
.@{class-prefix}-px-8 {
  padding-left: 3.5rem !important;
}

.@{class-prefix}-p-9 {
  padding: 4rem !important;
}

.@{class-prefix}-pt-9,
.@{class-prefix}-py-9 {
  padding-top: 4rem !important;
}

.@{class-prefix}-pr-9,
.@{class-prefix}-px-9 {
  padding-right: 4rem !important;
}

.@{class-prefix}-pb-9,
.@{class-prefix}-py-9 {
  padding-bottom: 4rem !important;
}

.@{class-prefix}-pl-9,
.@{class-prefix}-px-9 {
  padding-left: 4rem !important;
}

.@{class-prefix}-m-auto {
  margin: auto !important;
}

.@{class-prefix}-mt-auto,
.@{class-prefix}-my-auto {
  margin-top: auto !important;
}

.@{class-prefix}-mr-auto,
.@{class-prefix}-mx-auto {
  margin-right: auto !important;
}

.@{class-prefix}-mb-auto,
.@{class-prefix}-my-auto {
  margin-bottom: auto !important;
}

.@{class-prefix}-ml-auto,
.@{class-prefix}-mx-auto {
  margin-left: auto !important;
}

// Medium screen / desktop - 576
@media (min-width: @screen-sm-min) {
  .@{class-prefix}-m-sm-0 {
    margin: 0 !important;
  }

  .@{class-prefix}-mt-sm-0,
  .@{class-prefix}-my-sm-0 {
    margin-top: 0 !important;
  }

  .@{class-prefix}-mr-sm-0,
  .@{class-prefix}-mx-sm-0 {
    margin-right: 0 !important;
  }

  .@{class-prefix}-mb-sm-0,
  .@{class-prefix}-my-sm-0 {
    margin-bottom: 0 !important;
  }

  .@{class-prefix}-ml-sm-0,
  .@{class-prefix}-mx-sm-0 {
    margin-left: 0 !important;
  }

  .@{class-prefix}-m-sm-1 {
    margin: 0.25rem !important;
  }

  .@{class-prefix}-mt-sm-1,
  .@{class-prefix}-my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .@{class-prefix}-mr-sm-1,
  .@{class-prefix}-mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .@{class-prefix}-mb-sm-1,
  .@{class-prefix}-my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .@{class-prefix}-ml-sm-1,
  .@{class-prefix}-mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .@{class-prefix}-m-sm-2 {
    margin: 0.5rem !important;
  }

  .@{class-prefix}-mt-sm-2,
  .@{class-prefix}-my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .@{class-prefix}-mr-sm-2,
  .@{class-prefix}-mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .@{class-prefix}-mb-sm-2,
  .@{class-prefix}-my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .@{class-prefix}-ml-sm-2,
  .@{class-prefix}-mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .@{class-prefix}-m-sm-3 {
    margin: 1rem !important;
  }

  .@{class-prefix}-mt-sm-3,
  .@{class-prefix}-my-sm-3 {
    margin-top: 1rem !important;
  }

  .@{class-prefix}-mr-sm-3,
  .@{class-prefix}-mx-sm-3 {
    margin-right: 1rem !important;
  }

  .@{class-prefix}-mb-sm-3,
  .@{class-prefix}-my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .@{class-prefix}-ml-sm-3,
  .@{class-prefix}-mx-sm-3 {
    margin-left: 1rem !important;
  }

  .@{class-prefix}-m-sm-4 {
    margin: 1.5rem !important;
  }

  .@{class-prefix}-mt-sm-4,
  .@{class-prefix}-my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .@{class-prefix}-mr-sm-4,
  .@{class-prefix}-mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .@{class-prefix}-mb-sm-4,
  .@{class-prefix}-my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .@{class-prefix}-ml-sm-4,
  .@{class-prefix}-mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .@{class-prefix}-m-sm-5 {
    margin: 3rem !important;
  }

  .@{class-prefix}-mt-sm-5,
  .@{class-prefix}-my-sm-5 {
    margin-top: 3rem !important;
  }

  .@{class-prefix}-mr-sm-5,
  .@{class-prefix}-mx-sm-5 {
    margin-right: 3rem !important;
  }

  .@{class-prefix}-mb-sm-5,
  .@{class-prefix}-my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .@{class-prefix}-ml-sm-5,
  .@{class-prefix}-mx-sm-5 {
    margin-left: 3rem !important;
  }

  .@{class-prefix}-p-sm-0 {
    padding: 0 !important;
  }

  .@{class-prefix}-pt-sm-0,
  .@{class-prefix}-py-sm-0 {
    padding-top: 0 !important;
  }

  .@{class-prefix}-pr-sm-0,
  .@{class-prefix}-px-sm-0 {
    padding-right: 0 !important;
  }

  .@{class-prefix}-pb-sm-0,
  .@{class-prefix}-py-sm-0 {
    padding-bottom: 0 !important;
  }

  .@{class-prefix}-pl-sm-0,
  .@{class-prefix}-px-sm-0 {
    padding-left: 0 !important;
  }

  .@{class-prefix}-p-sm-1 {
    padding: 0.25rem !important;
  }

  .@{class-prefix}-pt-sm-1,
  .@{class-prefix}-py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .@{class-prefix}-pr-sm-1,
  .@{class-prefix}-px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .@{class-prefix}-pb-sm-1,
  .@{class-prefix}-py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .@{class-prefix}-pl-sm-1,
  .@{class-prefix}-px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .@{class-prefix}-p-sm-2 {
    padding: 0.5rem !important;
  }

  .@{class-prefix}-pt-sm-2,
  .@{class-prefix}-py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .@{class-prefix}-pr-sm-2,
  .@{class-prefix}-px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .@{class-prefix}-pb-sm-2,
  .@{class-prefix}-py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .@{class-prefix}-pl-sm-2,
  .@{class-prefix}-px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .@{class-prefix}-p-sm-3 {
    padding: 1rem !important;
  }

  .@{class-prefix}-pt-sm-3,
  .@{class-prefix}-py-sm-3 {
    padding-top: 1rem !important;
  }

  .@{class-prefix}-pr-sm-3,
  .@{class-prefix}-px-sm-3 {
    padding-right: 1rem !important;
  }

  .@{class-prefix}-pb-sm-3,
  .@{class-prefix}-py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .@{class-prefix}-pl-sm-3,
  .@{class-prefix}-px-sm-3 {
    padding-left: 1rem !important;
  }

  .@{class-prefix}-p-sm-4 {
    padding: 1.5rem !important;
  }

  .@{class-prefix}-pt-sm-4,
  .@{class-prefix}-py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .@{class-prefix}-pr-sm-4,
  .@{class-prefix}-px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .@{class-prefix}-pb-sm-4,
  .@{class-prefix}-py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .@{class-prefix}-pl-sm-4,
  .@{class-prefix}-px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .@{class-prefix}-p-sm-5 {
    padding: 2rem !important;
  }

  .@{class-prefix}-pt-sm-5,
  .@{class-prefix}-py-sm-5 {
    padding-top: 2rem !important;
  }

  .@{class-prefix}-pr-sm-5,
  .@{class-prefix}-px-sm-5 {
    padding-right: 2rem !important;
  }

  .@{class-prefix}-pb-sm-5,
  .@{class-prefix}-py-sm-5 {
    padding-bottom: 2rem !important;
  }

  .@{class-prefix}-pl-sm-5,
  .@{class-prefix}-px-sm-5 {
    padding-left: 2rem !important;
  }

  .@{class-prefix}-m-sm-auto {
    margin: auto !important;
  }

  .@{class-prefix}-mt-sm-auto,
  .@{class-prefix}-my-sm-auto {
    margin-top: auto !important;
  }

  .@{class-prefix}-mr-sm-auto,
  .@{class-prefix}-mx-sm-auto {
    margin-right: auto !important;
  }

  .@{class-prefix}-mb-sm-auto,
  .@{class-prefix}-my-sm-auto {
    margin-bottom: auto !important;
  }

  .@{class-prefix}-ml-sm-auto,
  .@{class-prefix}-mx-sm-auto {
    margin-left: auto !important;
  }
}

// Medium screen / desktop - 768
@media (min-width: @screen-md-min) {
  .@{class-prefix}-m-md-0 {
    margin: 0 !important;
  }

  .@{class-prefix}-mt-md-0,
  .@{class-prefix}-my-md-0 {
    margin-top: 0 !important;
  }

  .@{class-prefix}-mr-md-0,
  .@{class-prefix}-mx-md-0 {
    margin-right: 0 !important;
  }

  .@{class-prefix}-mb-md-0,
  .@{class-prefix}-my-md-0 {
    margin-bottom: 0 !important;
  }

  .@{class-prefix}-ml-md-0,
  .@{class-prefix}-mx-md-0 {
    margin-left: 0 !important;
  }

  .@{class-prefix}-m-md-1 {
    margin: 0.25rem !important;
  }

  .@{class-prefix}-mt-md-1,
  .@{class-prefix}-my-md-1 {
    margin-top: 0.25rem !important;
  }

  .@{class-prefix}-mr-md-1,
  .@{class-prefix}-mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .@{class-prefix}-mb-md-1,
  .@{class-prefix}-my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .@{class-prefix}-ml-md-1,
  .@{class-prefix}-mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .@{class-prefix}-m-md-2 {
    margin: 0.5rem !important;
  }

  .@{class-prefix}-mt-md-2,
  .@{class-prefix}-my-md-2 {
    margin-top: 0.5rem !important;
  }

  .@{class-prefix}-mr-md-2,
  .@{class-prefix}-mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .@{class-prefix}-mb-md-2,
  .@{class-prefix}-my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .@{class-prefix}-ml-md-2,
  .@{class-prefix}-mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .@{class-prefix}-m-md-3 {
    margin: 1rem !important;
  }

  .@{class-prefix}-mt-md-3,
  .@{class-prefix}-my-md-3 {
    margin-top: 1rem !important;
  }

  .@{class-prefix}-mr-md-3,
  .@{class-prefix}-mx-md-3 {
    margin-right: 1rem !important;
  }

  .@{class-prefix}-mb-md-3,
  .@{class-prefix}-my-md-3 {
    margin-bottom: 1rem !important;
  }

  .@{class-prefix}-ml-md-3,
  .@{class-prefix}-mx-md-3 {
    margin-left: 1rem !important;
  }

  .@{class-prefix}-m-md-4 {
    margin: 1.5rem !important;
  }

  .@{class-prefix}-mt-md-4,
  .@{class-prefix}-my-md-4 {
    margin-top: 1.5rem !important;
  }

  .@{class-prefix}-mr-md-4,
  .@{class-prefix}-mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .@{class-prefix}-mb-md-4,
  .@{class-prefix}-my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .@{class-prefix}-ml-md-4,
  .@{class-prefix}-mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .@{class-prefix}-m-md-5 {
    margin: 3rem !important;
  }

  .@{class-prefix}-mt-md-5,
  .@{class-prefix}-my-md-5 {
    margin-top: 3rem !important;
  }

  .@{class-prefix}-mr-md-5,
  .@{class-prefix}-mx-md-5 {
    margin-right: 3rem !important;
  }

  .@{class-prefix}-mb-md-5,
  .@{class-prefix}-my-md-5 {
    margin-bottom: 3rem !important;
  }

  .@{class-prefix}-ml-md-5,
  .@{class-prefix}-mx-md-5 {
    margin-left: 3rem !important;
  }

  .@{class-prefix}-p-md-0 {
    padding: 0 !important;
  }

  .@{class-prefix}-pt-md-0,
  .@{class-prefix}-py-md-0 {
    padding-top: 0 !important;
  }

  .@{class-prefix}-pr-md-0,
  .@{class-prefix}-px-md-0 {
    padding-right: 0 !important;
  }

  .@{class-prefix}-pb-md-0,
  .@{class-prefix}-py-md-0 {
    padding-bottom: 0 !important;
  }

  .@{class-prefix}-pl-md-0,
  .@{class-prefix}-px-md-0 {
    padding-left: 0 !important;
  }

  .@{class-prefix}-p-md-1 {
    padding: 0.25rem !important;
  }

  .@{class-prefix}-pt-md-1,
  .@{class-prefix}-py-md-1 {
    padding-top: 0.25rem !important;
  }

  .@{class-prefix}-pr-md-1,
  .@{class-prefix}-px-md-1 {
    padding-right: 0.25rem !important;
  }

  .@{class-prefix}-pb-md-1,
  .@{class-prefix}-py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .@{class-prefix}-pl-md-1,
  .@{class-prefix}-px-md-1 {
    padding-left: 0.25rem !important;
  }

  .@{class-prefix}-p-md-2 {
    padding: 0.5rem !important;
  }

  .@{class-prefix}-pt-md-2,
  .@{class-prefix}-py-md-2 {
    padding-top: 0.5rem !important;
  }

  .@{class-prefix}-pr-md-2,
  .@{class-prefix}-px-md-2 {
    padding-right: 0.5rem !important;
  }

  .@{class-prefix}-pb-md-2,
  .@{class-prefix}-py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .@{class-prefix}-pl-md-2,
  .@{class-prefix}-px-md-2 {
    padding-left: 0.5rem !important;
  }

  .@{class-prefix}-p-md-3 {
    padding: 1rem !important;
  }

  .@{class-prefix}-pt-md-3,
  .@{class-prefix}-py-md-3 {
    padding-top: 1rem !important;
  }

  .@{class-prefix}-pr-md-3,
  .@{class-prefix}-px-md-3 {
    padding-right: 1rem !important;
  }

  .@{class-prefix}-pb-md-3,
  .@{class-prefix}-py-md-3 {
    padding-bottom: 1rem !important;
  }

  .@{class-prefix}-pl-md-3,
  .@{class-prefix}-px-md-3 {
    padding-left: 1rem !important;
  }

  .@{class-prefix}-p-md-4 {
    padding: 1.5rem !important;
  }

  .@{class-prefix}-pt-md-4,
  .@{class-prefix}-py-md-4 {
    padding-top: 1.5rem !important;
  }

  .@{class-prefix}-pr-md-4,
  .@{class-prefix}-px-md-4 {
    padding-right: 1.5rem !important;
  }

  .@{class-prefix}-pb-md-4,
  .@{class-prefix}-py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .@{class-prefix}-pl-md-4,
  .@{class-prefix}-px-md-4 {
    padding-left: 1.5rem !important;
  }

  .@{class-prefix}-p-md-5 {
    padding: 2rem !important;
  }

  .@{class-prefix}-pt-md-5,
  .@{class-prefix}-py-md-5 {
    padding-top: 2rem !important;
  }

  .@{class-prefix}-pr-md-5,
  .@{class-prefix}-px-md-5 {
    padding-right: 2rem !important;
  }

  .@{class-prefix}-pb-md-5,
  .@{class-prefix}-py-md-5 {
    padding-bottom: 2rem !important;
  }

  .@{class-prefix}-pl-md-5,
  .@{class-prefix}-px-md-5 {
    padding-left: 2rem !important;
  }

  .@{class-prefix}-m-md-auto {
    margin: auto !important;
  }

  .@{class-prefix}-mt-md-auto,
  .@{class-prefix}-my-md-auto {
    margin-top: auto !important;
  }

  .@{class-prefix}-mr-md-auto,
  .@{class-prefix}-mx-md-auto {
    margin-right: auto !important;
  }

  .@{class-prefix}-mb-md-auto,
  .@{class-prefix}-my-md-auto {
    margin-bottom: auto !important;
  }

  .@{class-prefix}-ml-md-auto,
  .@{class-prefix}-mx-md-auto {
    margin-left: auto !important;
  }


  .@{class-prefix}-p--md6 {
    padding: 2.5rem !important;
  }

  .@{class-prefix}-pt-md-6,
  .@{class-prefix}-py-md-6 {
    padding-top: 2.5rem !important;
  }

  .@{class-prefix}-pr-md-6,
  .@{class-prefix}-px-md-6 {
    padding-right: 2.5rem !important;
  }

  .@{class-prefix}-pb-md-6,
  .@{class-prefix}-py-md-6 {
    padding-bottom: 2.5rem !important;
  }

  .@{class-prefix}-pl-md-6,
  .@{class-prefix}-px-md-6 {
    padding-left: 2.5rem !important;
  }

  .@{class-prefix}-p--md7 {
    padding: 3rem !important;
  }

  .@{class-prefix}-pt-md-7,
  .@{class-prefix}-py-md-7 {
    padding-top: 3rem !important;
  }

  .@{class-prefix}-pr-md-7,
  .@{class-prefix}-px-md-7 {
    padding-right: 3rem !important;
  }

  .@{class-prefix}-pb-md-7,
  .@{class-prefix}-py-md-7 {
    padding-bottom: 3rem !important;
  }

  .@{class-prefix}-pl-md-7,
  .@{class-prefix}-px-md-7 {
    padding-left: 3rem !important;
  }

  .@{class-prefix}-p--md8 {
    padding: 3.5rem !important;
  }

  .@{class-prefix}-pt-md-8,
  .@{class-prefix}-py-md-8 {
    padding-top: 3.5rem !important;
  }

  .@{class-prefix}-pr-md-8,
  .@{class-prefix}-px-md-8 {
    padding-right: 3.5rem !important;
  }

  .@{class-prefix}-pb-md-8,
  .@{class-prefix}-py-md-8 {
    padding-bottom: 3.5rem !important;
  }

  .@{class-prefix}-pl-md-8,
  .@{class-prefix}-px-md-8 {
    padding-left: 3.5rem !important;
  }

  .@{class-prefix}-p--md9 {
    padding: 4rem !important;
  }

  .@{class-prefix}-pt-md-9,
  .@{class-prefix}-py-md-9 {
    padding-top: 4rem !important;
  }

  .@{class-prefix}-pr-md-9,
  .@{class-prefix}-px-md-9 {
    padding-right: 4rem !important;
  }

  .@{class-prefix}-pb-md-9,
  .@{class-prefix}-py-md-9 {
    padding-bottom: 4rem !important;
  }

  .@{class-prefix}-pl-md-9,
  .@{class-prefix}-px-md-9 {
    padding-left: 4rem !important;
  }
}

// Large screen / wide desktop - 992
@media (min-width: @screen-lg-min) {
  .@{class-prefix}-m-lg-0 {
    margin: 0 !important;
  }

  .@{class-prefix}-mt-lg-0,
  .@{class-prefix}-my-lg-0 {
    margin-top: 0 !important;
  }

  .@{class-prefix}-mr-lg-0,
  .@{class-prefix}-mx-lg-0 {
    margin-right: 0 !important;
  }

  .@{class-prefix}-mb-lg-0,
  .@{class-prefix}-my-lg-0 {
    margin-bottom: 0 !important;
  }

  .@{class-prefix}-ml-lg-0,
  .@{class-prefix}-mx-lg-0 {
    margin-left: 0 !important;
  }

  .@{class-prefix}-m-lg-1 {
    margin: 0.25rem !important;
  }

  .@{class-prefix}-mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .@{class-prefix}-mr-lg-1,
  .@{class-prefix}-mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .@{class-prefix}-mb-lg-1,
  .@{class-prefix}-my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .@{class-prefix}-ml-lg-1,
  .@{class-prefix}-mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .@{class-prefix}-m-lg-2 {
    margin: 0.5rem !important;
  }

  .@{class-prefix}-mt-lg-2,
  .@{class-prefix}-my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .@{class-prefix}-mr-lg-2,
  .@{class-prefix}-mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .@{class-prefix}-mb-lg-2,
  .@{class-prefix}-my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .@{class-prefix}-ml-lg-2,
  .@{class-prefix}-mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .@{class-prefix}-m-lg-3 {
    margin: 1rem !important;
  }

  .@{class-prefix}-mt-lg-3,
  .@{class-prefix}-my-lg-3 {
    margin-top: 1rem !important;
  }

  .@{class-prefix}-mr-lg-3,
  .@{class-prefix}-mx-lg-3 {
    margin-right: 1rem !important;
  }

  .@{class-prefix}-mb-lg-3,
  .@{class-prefix}-my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .@{class-prefix}-ml-lg-3,
  .@{class-prefix}-mx-lg-3 {
    margin-left: 1rem !important;
  }

  .@{class-prefix}-m-lg-4 {
    margin: 1.5rem !important;
  }

  .@{class-prefix}-mt-lg-4,
  .@{class-prefix}-my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .@{class-prefix}-mr-lg-4,
  .@{class-prefix}-mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .@{class-prefix}-mb-lg-4,
  .@{class-prefix}-my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .@{class-prefix}-ml-lg-4,
  .@{class-prefix}-mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .@{class-prefix}-m-lg-5 {
    margin: 3rem !important;
  }

  .@{class-prefix}-mt-lg-5,
  .@{class-prefix}-my-lg-5 {
    margin-top: 3rem !important;
  }

  .@{class-prefix}-mr-lg-5,
  .@{class-prefix}-mx-lg-5 {
    margin-right: 3rem !important;
  }

  .@{class-prefix}-mb-lg-5,
  .@{class-prefix}-my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .@{class-prefix}-ml-lg-5,
  .@{class-prefix}-mx-lg-5 {
    margin-left: 3rem !important;
  }

  .@{class-prefix}-p-lg-0 {
    padding: 0 !important;
  }

  .@{class-prefix}-pt-lg-0,
  .@{class-prefix}-py-lg-0 {
    padding-top: 0 !important;
  }

  .@{class-prefix}-pr-lg-0,
  .@{class-prefix}-px-lg-0 {
    padding-right: 0 !important;
  }

  .@{class-prefix}-pb-lg-0,
  .@{class-prefix}-py-lg-0 {
    padding-bottom: 0 !important;
  }

  .@{class-prefix}-pl-lg-0,
  .@{class-prefix}-px-lg-0 {
    padding-left: 0 !important;
  }

  .@{class-prefix}-p-lg-1 {
    padding: 0.25rem !important;
  }

  .@{class-prefix}-pt-lg-1,
  .@{class-prefix}-py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .@{class-prefix}-pr-lg-1,
  .@{class-prefix}-px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .@{class-prefix}-pb-lg-1,
  .@{class-prefix}-py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .@{class-prefix}-pl-lg-1,
  .@{class-prefix}-px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .@{class-prefix}-p-lg-2 {
    padding: 0.5rem !important;
  }

  .@{class-prefix}-pt-lg-2,
  .@{class-prefix}-py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .@{class-prefix}-pr-lg-2,
  .@{class-prefix}-px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .@{class-prefix}-pb-lg-2,
  .@{class-prefix}-py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .@{class-prefix}-pl-lg-2,
  .@{class-prefix}-px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .@{class-prefix}-p-lg-3 {
    padding: 1rem !important;
  }

  .@{class-prefix}-pt-lg-3,
  .@{class-prefix}-py-lg-3 {
    padding-top: 1rem !important;
  }

  .@{class-prefix}-pr-lg-3,
  .@{class-prefix}-px-lg-3 {
    padding-right: 1rem !important;
  }

  .@{class-prefix}-pb-lg-3,
  .@{class-prefix}-py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .@{class-prefix}-pl-lg-3,
  .@{class-prefix}-px-lg-3 {
    padding-left: 1rem !important;
  }

  .@{class-prefix}-p-lg-4 {
    padding: 1.5rem !important;
  }

  .@{class-prefix}-pt-lg-4,
  .@{class-prefix}-py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .@{class-prefix}-pr-lg-4,
  .@{class-prefix}-px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .@{class-prefix}-pb-lg-4,
  .@{class-prefix}-py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .@{class-prefix}-pl-lg-4,
  .@{class-prefix}-px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .@{class-prefix}-p-lg-5 {
    padding: 2rem !important;
  }

  .@{class-prefix}-pt-lg-5,
  .@{class-prefix}-py-lg-5 {
    padding-top: 2rem !important;
  }

  .@{class-prefix}-pr-lg-5,
  .@{class-prefix}-px-lg-5 {
    padding-right: 2rem !important;
  }

  .@{class-prefix}-pb-lg-5,
  .@{class-prefix}-py-lg-5 {
    padding-bottom: 2rem !important;
  }

  .@{class-prefix}-pl-lg-5,
  .@{class-prefix}-px-lg-5 {
    padding-left: 2rem !important;
  }

  .@{class-prefix}-m-lg-auto {
    margin: auto !important;
  }

  .@{class-prefix}-mt-lg-auto,
  .@{class-prefix}-my-lg-auto {
    margin-top: auto !important;
  }

  .@{class-prefix}-mr-lg-auto,
  .@{class-prefix}-mx-lg-auto {
    margin-right: auto !important;
  }

  .@{class-prefix}-mb-lg-auto,
  .@{class-prefix}-my-lg-auto {
    margin-bottom: auto !important;
  }

  .@{class-prefix}-ml-lg-auto,
  .@{class-prefix}-mx-lg-auto {
    margin-left: auto !important;
  }
}

// Extra large screen / full hd - 1200
@media (min-width: @screen-xl-min) {
  .@{class-prefix}-m-xl-0 {
    margin: 0 !important;
  }

  .@{class-prefix}-mt-xl-0,
  .@{class-prefix}-my-xl-0 {
    margin-top: 0 !important;
  }

  .@{class-prefix}-mr-xl-0,
  .@{class-prefix}-mx-xl-0 {
    margin-right: 0 !important;
  }

  .@{class-prefix}-mb-xl-0,
  .@{class-prefix}-my-xl-0 {
    margin-bottom: 0 !important;
  }

  .@{class-prefix}-ml-xl-0,
  .@{class-prefix}-mx-xl-0 {
    margin-left: 0 !important;
  }

  .@{class-prefix}-m-xl-1 {
    margin: 0.25rem !important;
  }

  .@{class-prefix}-mt-xl-1,
  .@{class-prefix}-my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .@{class-prefix}-mr-xl-1,
  .@{class-prefix}-mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .@{class-prefix}-mb-xl-1,
  .@{class-prefix}-my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .@{class-prefix}-ml-xl-1,
  .@{class-prefix}-mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .@{class-prefix}-m-xl-2 {
    margin: 0.5rem !important;
  }

  .@{class-prefix}-mt-xl-2,
  .@{class-prefix}-my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .@{class-prefix}-mr-xl-2,
  .@{class-prefix}-mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .@{class-prefix}-mb-xl-2,
  .@{class-prefix}-my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .@{class-prefix}-ml-xl-2,
  .@{class-prefix}-mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .@{class-prefix}-m-xl-3 {
    margin: 1rem !important;
  }

  .@{class-prefix}-mt-xl-3,
  .@{class-prefix}-my-xl-3 {
    margin-top: 1rem !important;
  }

  .@{class-prefix}-mr-xl-3,
  .@{class-prefix}-mx-xl-3 {
    margin-right: 1rem !important;
  }

  .@{class-prefix}-mb-xl-3,
  .@{class-prefix}-my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .@{class-prefix}-ml-xl-3,
  .@{class-prefix}-mx-xl-3 {
    margin-left: 1rem !important;
  }

  .@{class-prefix}-m-xl-4 {
    margin: 1.5rem !important;
  }

  .@{class-prefix}-mt-xl-4,
  .@{class-prefix}-my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .@{class-prefix}-mr-xl-4,
  .@{class-prefix}-mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .@{class-prefix}-mb-xl-4,
  .@{class-prefix}-my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .@{class-prefix}-ml-xl-4,
  .@{class-prefix}-mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .@{class-prefix}-m-xl-5 {
    margin: 3rem !important;
  }

  .@{class-prefix}-mt-xl-5,
  .@{class-prefix}-my-xl-5 {
    margin-top: 3rem !important;
  }

  .@{class-prefix}-mr-xl-5,
  .@{class-prefix}-mx-xl-5 {
    margin-right: 3rem !important;
  }

  .@{class-prefix}-mb-xl-5,
  .@{class-prefix}-my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .@{class-prefix}-ml-xl-5,
  .@{class-prefix}-mx-xl-5 {
    margin-left: 3rem !important;
  }

  .@{class-prefix}-p-xl-0 {
    padding: 0 !important;
  }

  .@{class-prefix}-pt-xl-0,
  .@{class-prefix}-py-xl-0 {
    padding-top: 0 !important;
  }

  .@{class-prefix}-pr-xl-0,
  .@{class-prefix}-px-xl-0 {
    padding-right: 0 !important;
  }

  .@{class-prefix}-pb-xl-0,
  .@{class-prefix}-py-xl-0 {
    padding-bottom: 0 !important;
  }

  .@{class-prefix}-pl-xl-0,
  .@{class-prefix}-px-xl-0 {
    padding-left: 0 !important;
  }

  .@{class-prefix}-p-xl-1 {
    padding: 0.25rem !important;
  }

  .@{class-prefix}-pt-xl-1,
  .@{class-prefix}-py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .@{class-prefix}-pr-xl-1,
  .@{class-prefix}-px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .@{class-prefix}-pb-xl-1,
  .@{class-prefix}-py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .@{class-prefix}-pl-xl-1,
  .@{class-prefix}-px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .@{class-prefix}-p-xl-2 {
    padding: 0.5rem !important;
  }

  .@{class-prefix}-pt-xl-2,
  .@{class-prefix}-py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .@{class-prefix}-pr-xl-2,
  .@{class-prefix}-px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .@{class-prefix}-pb-xl-2,
  .@{class-prefix}-py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .@{class-prefix}-pl-xl-2,
  .@{class-prefix}-px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .@{class-prefix}-p-xl-3 {
    padding: 1rem !important;
  }

  .@{class-prefix}-pt-xl-3,
  .@{class-prefix}-py-xl-3 {
    padding-top: 1rem !important;
  }

  .@{class-prefix}-pr-xl-3,
  .@{class-prefix}-px-xl-3 {
    padding-right: 1rem !important;
  }

  .@{class-prefix}-pb-xl-3,
  .@{class-prefix}-py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .@{class-prefix}-pl-xl-3,
  .@{class-prefix}-px-xl-3 {
    padding-left: 1rem !important;
  }

  .@{class-prefix}-p-xl-4 {
    padding: 1.5rem !important;
  }

  .@{class-prefix}-pt-xl-4,
  .@{class-prefix}-py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .@{class-prefix}-pr-xl-4,
  .@{class-prefix}-px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .@{class-prefix}-pb-xl-4,
  .@{class-prefix}-py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .@{class-prefix}-pl-xl-4,
  .@{class-prefix}-px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .@{class-prefix}-p-xl-5 {
    padding: 2rem !important;
  }

  .@{class-prefix}-pt-xl-5,
  .@{class-prefix}-py-xl-5 {
    padding-top: 2rem !important;
  }

  .@{class-prefix}-pr-xl-5,
  .@{class-prefix}-px-xl-5 {
    padding-right: 2rem !important;
  }

  .@{class-prefix}-pb-xl-5,
  .@{class-prefix}-py-xl-5 {
    padding-bottom: 2rem !important;
  }

  .@{class-prefix}-pl-xl-5,
  .@{class-prefix}-px-xl-5 {
    padding-left: 2rem !important;
  }

  .@{class-prefix}-pl-xl-9,
  .@{class-prefix}-px-xl-9 {
    padding-left: 4rem !important;
  }

  .@{class-prefix}-m-xl-auto {
    margin: auto !important;
  }

  .@{class-prefix}-mt-xl-auto,
  .@{class-prefix}-my-xl-auto {
    margin-top: auto !important;
  }

  .@{class-prefix}-mr-xl-auto,
  .@{class-prefix}-mx-xl-auto {
    margin-right: auto !important;
  }

  .@{class-prefix}-mb-xl-auto,
  .@{class-prefix}-my-xl-auto {
    margin-bottom: auto !important;
  }

  .@{class-prefix}-ml-xl-auto,
  .@{class-prefix}-mx-xl-auto {
    margin-left: auto !important;
  }
}

// Extra large screen / Extra full hd - 1368
@media (min-width: (@screen-xl-min + 168px)) {
  .@{class-prefix}-m-xxl-0 {
    margin: 0 !important;
  }

  .@{class-prefix}-mt-xxl-0,
  .@{class-prefix}-my-xxl-0 {
    margin-top: 0 !important;
  }

  .@{class-prefix}-mr-xxl-0,
  .@{class-prefix}-mx-xxl-0 {
    margin-right: 0 !important;
  }

  .@{class-prefix}-mb-xxl-0,
  .@{class-prefix}-my-xxl-0 {
    margin-bottom: 0 !important;
  }

  .@{class-prefix}-ml-xxl-0,
  .@{class-prefix}-mx-xxl-0 {
    margin-left: 0 !important;
  }

  .@{class-prefix}-m-xxl-1 {
    margin: 0.25rem !important;
  }

  .@{class-prefix}-mt-xxl-1,
  .@{class-prefix}-my-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .@{class-prefix}-mr-xxl-1,
  .@{class-prefix}-mx-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .@{class-prefix}-mb-xxl-1,
  .@{class-prefix}-my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .@{class-prefix}-ml-xxl-1,
  .@{class-prefix}-mx-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .@{class-prefix}-m-xxl-2 {
    margin: 0.5rem !important;
  }

  .@{class-prefix}-mt-xxl-2,
  .@{class-prefix}-my-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .@{class-prefix}-mr-xxl-2,
  .@{class-prefix}-mx-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .@{class-prefix}-mb-xxl-2,
  .@{class-prefix}-my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .@{class-prefix}-ml-xxl-2,
  .@{class-prefix}-mx-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .@{class-prefix}-m-xxl-3 {
    margin: 1rem !important;
  }

  .@{class-prefix}-mt-xxl-3,
  .@{class-prefix}-my-xxl-3 {
    margin-top: 1rem !important;
  }

  .@{class-prefix}-mr-xxl-3,
  .@{class-prefix}-mx-xxl-3 {
    margin-right: 1rem !important;
  }

  .@{class-prefix}-mb-xxl-3,
  .@{class-prefix}-my-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .@{class-prefix}-ml-xxl-3,
  .@{class-prefix}-mx-xxl-3 {
    margin-left: 1rem !important;
  }

  .@{class-prefix}-m-xxl-4 {
    margin: 1.5rem !important;
  }

  .@{class-prefix}-mt-xxl-4,
  .@{class-prefix}-my-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .@{class-prefix}-mr-xxl-4,
  .@{class-prefix}-mx-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .@{class-prefix}-mb-xxl-4,
  .@{class-prefix}-my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .@{class-prefix}-ml-xxl-4,
  .@{class-prefix}-mx-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .@{class-prefix}-m-xxl-5 {
    margin: 3rem !important;
  }

  .@{class-prefix}-mt-xxl-5,
  .@{class-prefix}-my-xxl-5 {
    margin-top: 3rem !important;
  }

  .@{class-prefix}-mr-xxl-5,
  .@{class-prefix}-mx-xxl-5 {
    margin-right: 3rem !important;
  }

  .@{class-prefix}-mb-xxl-5,
  .@{class-prefix}-my-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .@{class-prefix}-ml-xxl-5,
  .@{class-prefix}-mx-xxl-5 {
    margin-left: 3rem !important;
  }

  .@{class-prefix}-p-xxl-0 {
    padding: 0 !important;
  }

  .@{class-prefix}-pt-xxl-0,
  .@{class-prefix}-py-xxl-0 {
    padding-top: 0 !important;
  }

  .@{class-prefix}-pr-xxl-0,
  .@{class-prefix}-px-xxl-0 {
    padding-right: 0 !important;
  }

  .@{class-prefix}-pb-xxl-0,
  .@{class-prefix}-py-xxl-0 {
    padding-bottom: 0 !important;
  }

  .@{class-prefix}-pl-xxl-0,
  .@{class-prefix}-px-xxl-0 {
    padding-left: 0 !important;
  }

  .@{class-prefix}-p-xxl-1 {
    padding: 0.25rem !important;
  }

  .@{class-prefix}-pt-xxl-1,
  .@{class-prefix}-py-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .@{class-prefix}-pr-xxl-1,
  .@{class-prefix}-px-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .@{class-prefix}-pb-xxl-1,
  .@{class-prefix}-py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .@{class-prefix}-pl-xxl-1,
  .@{class-prefix}-px-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .@{class-prefix}-p-xxl-2 {
    padding: 0.5rem !important;
  }

  .@{class-prefix}-pt-xxl-2,
  .@{class-prefix}-py-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .@{class-prefix}-pr-xxl-2,
  .@{class-prefix}-px-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .@{class-prefix}-pb-xxl-2,
  .@{class-prefix}-py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .@{class-prefix}-pl-xxl-2,
  .@{class-prefix}-px-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .@{class-prefix}-p-xxl-3 {
    padding: 1rem !important;
  }

  .@{class-prefix}-pt-xxl-3,
  .@{class-prefix}-py-xxl-3 {
    padding-top: 1rem !important;
  }

  .@{class-prefix}-pr-xxl-3,
  .@{class-prefix}-px-xxl-3 {
    padding-right: 1rem !important;
  }

  .@{class-prefix}-pb-xxl-3,
  .@{class-prefix}-py-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .@{class-prefix}-pl-xxl-3,
  .@{class-prefix}-px-xxl-3 {
    padding-left: 1rem !important;
  }

  .@{class-prefix}-p-xxl-4 {
    padding: 1.5rem !important;
  }

  .@{class-prefix}-pt-xxl-4,
  .@{class-prefix}-py-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .@{class-prefix}-pr-xxl-4,
  .@{class-prefix}-px-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .@{class-prefix}-pb-xxl-4,
  .@{class-prefix}-py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .@{class-prefix}-pl-xxl-4,
  .@{class-prefix}-px-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .@{class-prefix}-p-xxl-5 {
    padding: 2rem !important;
  }

  .@{class-prefix}-pt-xxl-5,
  .@{class-prefix}-py-xxl-5 {
    padding-top: 2rem !important;
  }

  .@{class-prefix}-pr-xxl-5,
  .@{class-prefix}-px-xxl-5 {
    padding-right: 2rem !important;
  }

  .@{class-prefix}-pb-xxl-5,
  .@{class-prefix}-py-xxl-5 {
    padding-bottom: 2rem !important;
  }

  .@{class-prefix}-pl-xxl-5,
  .@{class-prefix}-px-xxl-5 {
    padding-left: 2rem !important;
  }

  .@{class-prefix}-m-xxl-auto {
    margin: auto !important;
  }

  .@{class-prefix}-mt-xxl-auto,
  .@{class-prefix}-my-xxl-auto {
    margin-top: auto !important;
  }

  .@{class-prefix}-mr-xxl-auto,
  .@{class-prefix}-mx-xxl-auto {
    margin-right: auto !important;
  }

  .@{class-prefix}-mb-xxl-auto,
  .@{class-prefix}-my-xxl-auto {
    margin-bottom: auto !important;
  }

  .@{class-prefix}-ml-xxl-auto,
  .@{class-prefix}-mx-xxl-auto {
    margin-left: auto !important;
  }
}
