.@{class-prefix}-tariff-block {
  margin-top: 188px;
  padding: 0 @md-padding 200px;

  ul {
    list-style: none;
    padding-left: 60px;

    > li {
      position: relative;
      margin-bottom: 25px;
      &::before {
        content: '';
        margin-left: -30px;
        margin-right: 15px;
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #2665C3;
        position: absolute;
        left: -10px;
        top: 5px;
      }
    }
  }


  &-preview {

    &-title {
      margin-bottom: 20px;
    }

    &-content {
      background: #F5F5F5;
      box-shadow: -8px -8px 10px rgba(79, 113, 163, 0.1), 8px 8px 10px rgba(27, 43, 73, 0.2);
      border-radius: 4px;

      padding: 46px;

      .rate-list__item {
        font-family: 'Noto Sans', sans-serif;
        font-size: 12px;
        margin: 0;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(44, 115, 255, 0.1);
        border-radius: 4px;

        &:not(:last-of-type) {
          margin-bottom: 20px;
        }
      }
    }
  }

  @media (max-width: @screen-sm-max) {
    margin-top: 90px;
    padding: 0 40px 100px;

  }
}
