.@{class-prefix}-additional-services {
  padding: 0 @md-padding 100px;

  &-features {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 39px;

    &-container {
      height: 100%;


      &:nth-child(1) {
        padding-bottom: 37px;
      }

      &:nth-child(2) {
        padding-top: 37px;
        padding-right: 24px;
      }

      &:nth-child(3) {
        padding-left: 73px;
      }

      &:nth-child(4) {
        padding-top: 35px;
      }
    }

    &-content {
      border-radius: 4px;
      padding: 41px 23px;

      background: linear-gradient(180deg, #2665C4 0%, #1C56AE 100%);
      box-shadow: -8px -8px 10px rgba(44, 115, 255, 0.1), 8px 8px 10px rgba(44, 115, 255, 0.2);
      text-align: center;
      color: @white;
      font-size: 14px;
      font-weight: 600;

      &-alternative {
        background: linear-gradient(180deg, #E63D49 0%, #D5303B 100%);
        box-shadow: -8px -8px 10px rgba(255, 9, 9, 0.1), 8px 8px 10px rgba(255, 9, 9, 0.2);
      }
    }
  }

  @media (max-width: @screen-sm-max) {
    padding: 0 40px 100px;
  }

  @media (max-width: @screen-xs-max) {
    &-features {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;

      row-gap: 20px;

      &-container {
      height: 100%;


      &:nth-child(1) {
        padding: 0;
      }

      &:nth-child(2) {
        padding: 0;
      }

      &:nth-child(3) {
        padding: 0;
      }

      &:nth-child(4) {
        padding: 0;
      }
    }
    }
  }
}
