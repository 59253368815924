
.@{class-prefix}-w-auto {
  width: auto !important;
}

.@{class-prefix}-w-25 {
  width: 25% !important;
}

.@{class-prefix}-w-50 {
  width: 50% !important;
}

.@{class-prefix}-w-75 {
  width: 75% !important;
}

.@{class-prefix}-w-100 {
  width: 100% !important;
}

.@{class-prefix}-h-25 {
  height: 25% !important;
}

.@{class-prefix}-h-50 {
  height: 50% !important;
}

.@{class-prefix}-h-75 {
  height: 75% !important;
}

.@{class-prefix}-h-100 {
  height: 100% !important;
}

.@{class-prefix}-h-auto {
  height: auto !important;
}

.@{class-prefix}-z-index-10 {
  z-index: 10;
}

.@{class-prefix}-relative {
  position: relative;
}


.@{class-prefix}-pointer {
  cursor: pointer !important;
}
