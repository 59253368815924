.@{class-prefix}-footer {
  background: linear-gradient(180deg, #0D2850 0%, #132A4D 100%);
  padding-top: 52px;
  padding-bottom: 32px;

  &-link {
    color: @white;
    transition: color .2s ease;
    margin: 0 13px;

    &:hover {
      color: fade(@white, 80%);
    }
  }

  &-logo {
    max-width: 184px;
    margin-bottom: 49px;
  }

  &-links {
    margin-bottom: 49px;
  }

  &-capture {
    font-size: 14px;
  }

  @media (max-width: @screen-sm-max) {
    &-links {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-link {
      margin: 13px 0;
      padding: 0 13px;
    }
  }
}
