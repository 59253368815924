.@{class-prefix}-tracking {
  padding: 0 @md-padding + 214px;
  margin-top: 188px;

  &-form {
    display: grid;
    grid-template-columns: 1.4fr 0.6fr;
    column-gap: 10px;
    row-gap: 10px;

    input {
      height: 58px;
      border: 0 !important;
      box-shadow: none !important;
      //text-align: center !important;

      &::placeholder {
        color: #4F71A3;
        font-size: 16px;
        font-weight: 600;
        //text-align: center;
      }

      &:focus {
        border: 0 !important;
        box-shadow: none !important;
      }

      position: relative;
    }

    label {
      margin-bottom: 7px;
      display: block;
    }
  }

  &-list {
    margin-top: 134px;
    padding: 0 100px;

    .ant-timeline {
      &-item {

        &-head {
          width: 24px;
          height: 24px;
          background: #3C75F3;
          top: -9px;
          left: -7px;
          border-color: #3C75F3;
        }

        &-tail {
          border-left: 2px solid #000000;
          opacity: .2;
        }

        &-content {
          margin: 0 0 0 42px;
        }
      }

    }
  }


  @media (max-width: @screen-lg-max) {
    padding: 0 @md-padding;
  }

  @media (max-width: @screen-sm-max) {
    padding: 0 40px 100px;
    &-list {
      padding: 0 20px;
    }
  }

  @media (max-width: @screen-xs-max) {
    &-form {
      grid-template-columns: 1fr;
    }
  }


  &-page {
    &-form {
      padding: 0 110px;
      display: grid;
      grid-template-columns: 1.4fr 0.6fr;
      column-gap: 10px;
      row-gap: 10px;

      input {
        height: 58px;
        border: 0 !important;
        box-shadow: none !important;
        //text-align: center !important;

        &::placeholder {
          color: #4F71A3;
          font-size: 16px;
          font-weight: 600;
          //text-align: center;
        }

        &:focus {
          border: 0 !important;
          box-shadow: none !important;
        }

        position: relative;
      }

      label {
        margin-bottom: 7px;
        display: block;
      }
    }


    @media (max-width: @screen-sm-max) {
      &-form {
        padding: 0 40px;
      }

      .@{class-prefix}-tracking {

        &-list {
          padding: 0 40px;

        }
      }

    }

    .@{class-prefix}-tracking {

      &-list {
        padding: 0 110px;

      }

    }

  }
}


