body {
  background-color: #f5f5f5;
  font-weight: 500;
  scroll-behavior: smooth;
}

@import "variables";
@import "mixins";
@import "button";
@import "typography";
@import "flex";
@import "base";
@import "margin-padding";
