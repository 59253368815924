@font-face {
  font-family: 'Noto Sans';
  src: url('NotoSans-Light.ttf') format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('NotoSans-Regular.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('NotoSans-Medium.ttf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('NotoSans-SemiBold.ttf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('NotoSans-Bold.ttf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('NotoSans-Black.ttf') format('opentype');
  font-weight: 900;
  font-style: normal;
}
