.@{class-prefix}-clients {
  padding: 0 @md-padding;
  padding-bottom: 100px;

  @media (max-width: @screen-sm-max) {
    padding: 0 40px 100px;

  }

  &-list {
    //display: grid;
    //grid-template-columns: minmax(250px, 1fr) minmax(250px, 1fr) minmax(250px, 1fr) minmax(250px, 1fr);
    //column-gap: 40px;
    //align-items: center;

    display: flex;
    justify-content: center;

    > * {
      max-width: 250px;
      max-height: 130px;
      margin: 0 20px;

      > * {
        max-width: 250px;
        max-height: 130px;
      }
    }
  }
}
