@header-class: @{class-prefix}-header;

.@{class-prefix}-header {
  background: linear-gradient(108.65deg, #203255 11.19%, #14294F 94.08%);
  padding: 45px 0 77px;
  color: @white;
  position: relative;

  &-logo {
    max-width: 184px;
    &.is-winter {
      margin-top: -40px;
    }
  }

  &-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 110px;

    &-links {
      > a {
        margin: 0 13px;
      }
    }
  }

  &-title {
    text-align: center;
    font-size: 45px;
    font-weight: 600;

    margin-top: 190px;

    color: @white;
  }

  &-form {
    text-align: center;
    height: 70px;
    border: 1px solid red;
    width: 100%;

    margin-top: 40px;
  }

  &-subtitle {
    margin-top: 100px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
  }

  &-scroll {
    margin-top: 58px;

    text-align: center;
  }

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  //&-content {
  //  z-index: 10;
  //  position: relative;
  //}


  @media (max-width: @screen-md-max) {
    &-navigation-links {
      display: none;
    }
    .pls-button-white {
      display: none;
    }

    &-drawer {
      .@{class-prefix}-header {
        &-navigation {
          &-links {
            display: flex;
            flex-direction: column;

            > a {
              color: @dark-blue !important;
            }
          }
        }

      }

      .pls-button-white {
        width: 100%;

        .button-variant-primary(#ffffff, #1B2B49)
      }
    }
  }

  @media (max-width: @screen-sm-max) {

    &-logo {
      &.is-winter {
        margin-top: -20px;
      }
    }

    &-title {
      font-size: 40px;
      margin-top: 100px;
    }

    &-navigation {
      padding: 0 40px;
    }
  }

  @media (max-width: @screen-lg-max) {
    &-logo {
      max-width: 100px;
    }
  }

}
