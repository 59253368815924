.@{class-prefix}-why {

  padding: 0 @md-padding;

  padding-bottom: 100px;


  &-grid {
    margin-top: 80px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
                  "first second"
                  "third fifth"
                  "fourth sixth";

    max-width: 1015px;


    grid-template-rows: 1fr 1fr 1fr;
    gap: 20px 22px;

    &-item {
      box-shadow: -8px -8px 10px rgba(44, 115, 255, 0.1), 8px 8px 10px rgba(44, 115, 255, 0.2);
      border-radius: 4px;
      background: linear-gradient(180deg, #132A4D 0%, #081B37 100%);
      padding: 45px 42px 54px;
      color: @white;
      //text-align: center;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      text-align: center;

      &-alternative {
        background: linear-gradient(180deg, #2665C4 0%, #1C56AE 100%);

        .@{class-prefix}-why-grid-item-number {
          color: #2665C3;
        }
      }

      &-number {
        width: 74px;
        height: 74px;
        border-radius: 50%;
        background-color: @white;

        color: #1B2B49;
        font-size: 28px;
        font-weight: 700;
        text-align: center;

        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 33px;
      }

      &:nth-child(1) {
        grid-area: first;
      }

      &:nth-child(2) {
        grid-area: second;
      }

      &:nth-child(3) {
        grid-area: third;
      }

      &:nth-child(4) {
        grid-area: fourth;
      }

      &:nth-child(5) {
        grid-area: fifth;
      }

      &:nth-child(6) {
        grid-area: sixth;
      }

    }

    @media (min-width: @screen-lg-min) {
      grid-template-columns: 1fr 1.3fr 1fr;
      grid-template-areas:
                  "first second third"
                  "first fifth third"
                  "fourth fifth sixth";
      grid-template-rows: 1.4fr 0.2fr 1.4fr
    }

  }

  @media (max-width: @screen-sm-max) {
    padding: 0 40px 100px;

  }

  @media (max-width: @screen-xs-max) {

    &-grid {
      grid-template-columns: 1fr;
      grid-template-areas:
                  "first"
                  "second"
                  "third"
                  "fourth"
                  "fifth"
                  "sixth"
    }
  }

}
