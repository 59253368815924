.@{class-prefix}-features {

  padding: 0 @md-padding;
  padding-bottom: 100px;

  &-feature {
    position: relative;
    padding-top: 19px;
    padding-left: 53px;
    margin-bottom: 69px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 70px;
      height: 70px;
      background: linear-gradient(180deg, #E63D49 0%, #D5303B 100%);
      box-shadow: -4px -4px 8px rgba(255, 9, 9, 0.1), 4px 4px 8px rgba(255, 9, 9, 0.2);
      border-radius: 50%;
      z-index: -1;
    }

  }
  @media (max-width: @screen-sm-max) {
    padding: 0 40px 100px;

  }
}
