.@{class-prefix}-rounded-background {
  position: relative;
  margin-bottom: -100px;

  &-content {
    background-color: @white;
  }

  &-bottom {
    width: 100%;
  }

  &-top {
    width: 100%;
    margin-bottom: -10px;
  }
}
