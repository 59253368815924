.@{class-prefix}-calculation {
  padding: 0 110px;

  &__select-option {
    .ant-select-item-option-content {
      white-space: normal;
    }
  }

  &-form {
    display: grid;
    column-gap: 14px;
    // percents to enforce size of child components to deny stretch
    grid-template-columns: 30% 30% 20% 20%;
    row-gap: 10px;

    @media (max-width: @screen-md-max) {
      grid-template-columns: repeat(2, 50%);
    }


    @media (max-width: @screen-xs-max) {
      grid-template-columns: 100%;
    }

    @vertical-padding: 17.5px;

    input {
      font-size: 16px !important;
      font-weight: 600 !important;
    }

    label {
      margin-bottom: 7px;
      display: block;
    }

    .ant-select {

      &-selector {
        padding: @vertical-padding 10px !important;
        height: auto !important;

        &::after {
          line-height: 1.2 !important;
        }


        input {
          height: auto !important;
        }
      }

      &-selection {
        &-search {
          top: @vertical-padding - 6px !important;
        }

        &-item {
          line-height: 1.2 !important;
          font-size: 16px;
          padding: 0;
          white-space: pre-wrap;
        }

        &-placeholder {
          line-height: 1.2 !important;
          color: #4F71A3;
          font-size: 16px;
        }
      }

    }

    &-cargo {
      input {
        height: 58px;
        border: 0 !important;
        box-shadow: none !important;
        //text-align: center !important;

        &::placeholder {
          color: #4F71A3;
          font-size: 16px;
          font-weight: 600;
          //text-align: center;
        }

        &:focus {
          border: 0 !important;
          box-shadow: none !important;
        }

        position: relative;

        &:nth-of-type(1) {
          padding-right: 10px;

          + span {
            position: absolute;
            left: calc(33.33% ~'-' 5px);
            z-index: 10;
            top: 50%;
            transform: translateY(-50%);
            color: #4F71A3;
            font-size: 16px;
            font-weight: 600;
          }
        }

        &:nth-of-type(2) {
          padding-left: 5px;
          padding-right: 5px;

          + span {
            position: absolute;
            left: calc(66.66% ~'-' 5px);
            z-index: 10;
            top: 50%;
            transform: translateY(-50%);
            color: #4F71A3;
            font-size: 16px;
            font-weight: 600;
          }
        }

        &:nth-of-type(3) {
          padding-left: 10px;
        }
      }

      &-dimensions {

        .ant-input-group {
          //&:last-of-type(input) {
          //  border-bottom-right-radius: 0;
          //  border-top-right-radius: 0;
          //}

          .ant-input:last-child {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }

      &-weight {
        .ant-input {
          //border-bottom-left-radius: 0;
          //border-top-left-radius: 0;
          //border-left: 0;
        }
      }
    }
  }

  &-result {
    padding: 75px 200px;

    .rate-list__item {
      margin-right: 0;
      margin-left: 2rem;
      background-color: @white;
    }

    .ant-collapse {
      background-color: transparent;
      border: 0;

      &-content {
        border: 0;
        background-color: transparent;
      }

      &-item {
        border: 0;
      }

      .ant-checkbox-inner {
        border-radius: 5px;
      }
    }

    .ant-select {
        &-selector {
          background-color: transparent !important;

        }
      }
  }

  @media (max-width: @screen-sm-max) {
    padding: 0 40px;

    &-result {
      padding: 75px 40px;

      .rate-list__item {
        margin-left: 0;
      }
    }
  }
}
