.@{class-prefix}-creation {
  padding: 0 @md-padding;
  margin-top: 188px;
  padding-bottom: 200px;


  &-step {

    &-list {
      margin-top: 74px;
    }

    &-image {
      background-color: @white;
      border-radius: 50%;
      width: 133px;
      height: 133px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-top: 44px;
      margin-bottom: 44px;
    }
  }

  @media (max-width: @screen-sm-max) {
    padding: 0 40px 100px;

    margin-top: 0;

    &-step {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-image {
        order: 2;
      }

      &-description {
        order: 1;
      }
    }
  }

}
